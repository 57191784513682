#scrollDown {
  position: fixed;
  bottom: 30px;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 60px;
  height: 60px;
  z-index: 999999;
  cursor: pointer;
  background: #0077CA;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  border-radius: 50%;
  -webkit-box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.25);
          box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.25);
  -webkit-transition: all 0.5s cubic-bezier(0.25, 0.1, 0.25, 1);
  transition: all 0.5s cubic-bezier(0.25, 0.1, 0.25, 1);
  -webkit-animation-name: bounce;
          animation-name: bounce;
  -webkit-animation-duration: 0.75s;
          animation-duration: 0.75s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-timing-function: ease-in-out;
          animation-timing-function: ease-in-out;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
}
#scrollDown.hide {
  opacity: 0;
  bottom: 0;
  -webkit-transform: translateY(-100%);
          transform: translateY(-100%);
}
#scrollDown:before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  width: 2px;
  height: 20px;
  -webkit-transform-origin: bottom center;
          transform-origin: bottom center;
  -webkit-transform: rotate(45deg) translateY(1px);
          transform: rotate(45deg) translateY(1px);
  background: #fff;
}
#scrollDown:after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  width: 2px;
  height: 20px;
  -webkit-transform: rotate(-45deg) translateY(1px);
          transform: rotate(-45deg) translateY(1px);
  -webkit-transform-origin: bottom center;
          transform-origin: bottom center;
  background: #fff;
}
@media (min-width: 992px) {
#scrollDown {
    bottom: 120px;
}
}
@-webkit-keyframes bounce {
0%, 100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
}
50% {
    -webkit-transform: translateY(-15px);
            transform: translateY(-15px);
}
}
@keyframes bounce {
0%, 100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
}
50% {
    -webkit-transform: translateY(-15px);
            transform: translateY(-15px);
}
}