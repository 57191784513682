.edenredLoginModal {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  min-width: 100%;
  min-height: 100%;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  cursor: pointer;
}
.edenredLoginModal iframe {
  width: 100%;
  height: 100%;
}
.edenredLoginModal svg {
  cursor: pointer;
  width: auto;
  height: auto;
}
.edenredLoginModal .textOnly {
  text-decoration: underline;
  font-weight: bold;
}
.edenredLoginModal .small-logo {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.edenredLoginModal .small-logo img {
  width: 60px;
  height: auto;
}
.edenredLoginModal .small-logo * {
  color: #162056;
  font-size: 14px;
  font-weight: bold;
}
#edenredLogin .modal-content {
  height: 90vh;
  max-height: 600px;
}
#edenredLogin iframe {
  width: 100%;
  height: 100%;
  border: 0;
  padding: 0;
  margin: 0;
}